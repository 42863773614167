import React, { useEffect } from 'react'
import { Button, Toast, ToastBody } from 'reactstrap'
import { observer } from 'mobx-react-lite'
import { AlertCircle, AlertOctagon, CheckCircle, X } from 'react-feather'
import { useRootStore } from '../store/rootStore'
import { ToastType, ToastData } from '../types/ToastData'

const ToastNotification = ({ toastData }: { toastData: ToastData }) => {
    const { appStore } = useRootStore()
   
    useEffect(() => {
        const interval = setTimeout(() => {
            appStore.deleteToast(toastData.id)
        }, toastData.time ? toastData.time : 3500)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const handleToastClass = (type: ToastType) => {
      switch(type) {
            case ToastType.Error:
                return 'd-flex align-items-center toast-error'
            case ToastType.Success:
                return 'd-flex align-items-center toast-success'
            case ToastType.Warning:
                return 'd-flex align-items-center toast-warning'
            case ToastType.Activation:
                return 'd-flex align-items-center toast-success'
            default:
                return 'd-none'
        }
    }

    const handleToastIcon = (type: ToastType) => {
        switch(type) {
            case ToastType.Error:
                return <AlertCircle color='red' size={40} strokeWidth={1.5} className='ml-2' />
            case ToastType.Success:
                return <CheckCircle color='#01dfc4' size={40} strokeWidth={1.5} className='ml-2' />
            case ToastType.Warning:
                return <AlertOctagon color='#ff9800' size={40} strokeWidth={1.5} className='ml-2' />
            case ToastType.Activation:
                return <CheckCircle color='#01dfc4' size={40} strokeWidth={1.5} className='ml-2' />
            default:
                return null
        }
    }

    return <Toast className={handleToastClass(toastData.type)} style={{zIndex: 99999}}>
        {handleToastIcon(toastData.type)}
        <div>
            <div className='toast-header justify-content-between'>
                <span className={toastData.type}><strong>{toastData.title}</strong></span>
                <Button className='button-close'
                    style={{ backgroundColor: 'transparent', borderColor: 'transparent' }}
                    onClick={() => appStore.deleteToast(toastData.id)}>
                    <X size={14} />
                </Button>
            </div>
            <ToastBody>
                <small style={{  whiteSpace: 'pre-line' }}>{toastData.body}</small>
            </ToastBody>
        </div>
    </Toast>
}
export const RatargetingNotificationBody = () => (<span>You successfully created a retargeting segment</span>)
export default observer(ToastNotification)

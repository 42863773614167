import { makeAutoObservable, onBecomeObserved, runInAction } from 'mobx'
import { RootStore } from './rootStore'
import { CampaignDto, CampaignProposal, CampaignStatus, ProposalStatus } from '../types/Campaign'
import { CampaignService } from '../services/campaignService'
import { ToastType } from '../types/ToastData'
import { segmentService } from '../services/segmentService'
import { SegmentDto } from '../types'
import { adminService } from '../services/adminService'

export class CampaignStore {
    private campaignService: CampaignService
    private rootStore: RootStore

    error: string = null
    campaigns: CampaignDto[]
    proposals: CampaignProposal[]
    createdId: number
    marketCampaigns: CampaignDto[]

    constructor(rootStore: RootStore, campaignService: CampaignService) {
        this.campaignService = campaignService
        this.rootStore = rootStore
        this.campaigns = []
        this.marketCampaigns = []
        this.createdId = 0

        makeAutoObservable(this)

        onBecomeObserved(this, 'campaigns', async () => {
            if (this.campaigns.length > 0) return
            const res = this.rootStore.authStore.isAdmin ? await adminService.getCampaignsAdmin() : await this.campaignService.getCampaigns()
            runInAction(() => { this.campaigns = res ? res : [] })
        })

        onBecomeObserved(this, 'marketCampaigns', async () => {
            if (this.marketCampaigns.length > 0) return
            if (this.rootStore.companyStore.myCompany.isPublisher) {
                const res = await this.campaignService.getMarketPublisher()
                runInAction(() => { this.marketCampaigns = res ? res : [] })
            }
        })

    }

    campaignDuration = (campaign: CampaignDto) => {
        if (campaign?.startDate && campaign?.endDate) {
            return Math.ceil((new Date(campaign.endDate).getTime() - new Date(campaign.startDate).getTime()) / (1000 * 3600 * 24))
        }
    }

    handleAssignSegments = async (campaign: CampaignDto) => {

        const isAssignedSegment = (segment: any) => !!segment.campaignId

        for await (const segment of campaign.segments) {
            if (!isAssignedSegment(segment)) {
                this.assign(segment, campaign.id)
            }
        }
    }

    isEmptyFC = (campaign: CampaignDto) => {
        if (campaign.frequencyCap && Array.isArray(campaign.frequencyCap) && campaign.frequencyCap.length > 0) {
            return campaign.frequencyCap && campaign.frequencyCap.length > 0 && campaign.frequencyCap.every(x => +x.freqRate > 0 && !!x.freqTiming && !!x.freqTiming.value)
        }
        if (campaign.frequencyCap && !Array.isArray(campaign.frequencyCap)) {
            return campaign.frequencyCap && Object.keys(campaign.frequencyCap).length > 0 && Object.values(campaign.frequencyCap).some(x => +x > 0)
        }
        else {
            return false
        }      
    }

    generateFCScript = (id: number) => {

        const campaign = this.campaigns.find(x => x.id === id)

        const mapFCToString = (obj: unknown) => {
            const result = Object.entries(obj).reduce((acc, [key, value]) => {
              if (value > 0) {
                return [...acc, `${value}x${Number(key.substring(1))}`]
              }
              return acc
            }, []).join(',')
            return result
          }

        const isProd = window.location.hostname.includes('idward.com') || window.location.hostname.includes('anonymised')

        return `<script src="https://storage.googleapis.com/idward-cmp-v2-${isProd ? 'master' : 'dev'}/light/impcounter.js" freqCapping="${campaign.id}:${mapFCToString(campaign.frequencyCap)}:${campaign.segments.map(x => x.publicName).join(',')}"></script>`
    }

    isValidPublish = (campaign: CampaignDto) => {
        return campaign.seatId.length > 0 && campaign.name.length > 0 && !!campaign.ads[0] && !!campaign.dspName
            && typeof campaign.segments !== 'undefined' && !!campaign.segments && campaign.segments.length > 0 && campaign.ads.every(x => +x.cpm > 0)
    }

    async createProposal(campaign: CampaignDto, status: ProposalStatus) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.campaignService.createProposal(campaign, status)
            runInAction(() => {
                const index = this.marketCampaigns.findIndex(x => x.id === campaign.id)
                if (this.marketCampaigns[index].proposals) {
                    this.marketCampaigns[index].proposals.push(res)
                }
                else {
                    this.marketCampaigns[index].proposals = [res]
                }
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: `Not ${status === ProposalStatus.Accepted ? 'Accepted' : 'Rejected'}`,
                body: `The campaign was not ${status === ProposalStatus.Accepted ? 'Accepted' : 'Rejected'}`,
                isHidden: false
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: `${status === ProposalStatus.Accepted ? 'Accepted' : 'Rejected'}`,
                    body: `The campaign was successfully ${status === ProposalStatus.Accepted ? 'Accepted. The owner of the campaign will be notified' : 'Rejected'}`,
                    isHidden: false
                })
            }
        }
    }

    async getPublisherCampaigns() {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.campaignService.getMarketPublisher()
            runInAction(() => { this.marketCampaigns = res ? res : [] })
        } catch (error) {
            runInAction(() => { this.error = error })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async create(campaign: CampaignDto, isToastHidden?: boolean) {
        runInAction(() => {
            this.error = null
            this.createdId = 0
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.campaignService.create(campaign)
            resp.companyName = this.rootStore.companyStore.myCompany.name
            resp.companyEmail = this.rootStore.companyStore.myCompany.companyEmail
            runInAction(() => {
                this.campaigns.unshift(resp)
                this.createdId = resp.id
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Saved',
                body: 'The campaign was not saved',
                isHidden: false
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            this.rootStore.appStore.setCreateCampaignModal(false)
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Saved',
                    body: 'The campaign was successfully saved',
                    isHidden: isToastHidden
                })
            }
        }
    }

    async assign(segment: any, campaignId: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const ID = campaignId > 0 ? campaignId : this.createdId
            const resp = await segmentService.assign(segment, ID)
            const publishedIndex = this.rootStore.segmentStore.segments.findIndex(x => x.id === resp.id)
            const newSegment = { publicName: resp.publicName, id: resp.id }

            runInAction(() => {
                this.rootStore.segmentStore.segments[publishedIndex] = resp
                const index = this.campaigns.findIndex(x => x.id === ID)

                if (typeof this.campaigns[index].segments === 'undefined') {
                    this.campaigns[index].segments = [newSegment]
                }
                if (this.campaigns[index].segments.every(x => x.id !== segment.id)) {
                    this.campaigns[index].segments = [...this.campaigns[index].segments, newSegment]
                }
                else {
                    const segmentIndex = this.campaigns[index].segments.findIndex(x => x.id === segment.id)
                    this.campaigns[index].segments[segmentIndex] = newSegment
                }
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Assigned',
                body: 'The segment was not assigned',
                isHidden: false
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            this.rootStore.appStore.setCreateCampaignModal(false)
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Assigned',
                    body: 'The segment was successfully assigned',
                    isHidden: true
                })
            }
        }
    }

    async detach(segment: SegmentDto, campaignId: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            await segmentService.detach(segment, campaignId)
            runInAction(() => {
                const index = this.campaigns.findIndex(x => x.id === campaignId)

                const segmentIndex = this.campaigns[index].segments.findIndex(x => x.id === segment.id)
                this.campaigns[index].segments = this.campaigns[index].segments.length < 2 ? [] : this.campaigns[index].segments.splice(segmentIndex, 1)
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to detach the segment from campaign'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async edit(campaign: CampaignDto, isToastHidden?: boolean) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            this.campaignService.edit(campaign)
            runInAction(() => {
                const index = this.campaigns.findIndex(x => x.id === campaign.id)
                this.campaigns[index] = campaign
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Edited',
                body: 'The campaign was not edited',
                isHidden: true
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            this.rootStore.appStore.setCreateCampaignModal(false)
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Edited',
                    body: 'The campaign was successfully edited',
                    isHidden: isToastHidden
                })
            }
        }
    }

    async publish(campaign: CampaignDto, campaignId?: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const ID = campaignId === 0 ? this.createdId : campaign.id
            const index = this.campaigns.findIndex(x => x.id === ID)
            this.campaignService.changeStatus(ID, CampaignStatus.Published)
            runInAction(() => {
                this.campaigns[index].status = CampaignStatus.Published
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Published',
                body: 'The campaign was not published',
                isHidden: false
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Activation,
                    title: 'Published',
                    body: 'The campaign was successfully published',
                    isHidden: false
                })
            }
        }
    }

    async deleteCampaign(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            this.rootStore.authStore.isAdmin ? await adminService.deleteCampaignAdmin(id) : await this.campaignService.deleteCampaign(id)
            runInAction(() => { this.campaigns = this.campaigns.filter(x => x.id !== id) })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Deleted',
                body: 'The campaign was not deleted',
                isHidden: false
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Deleted',
                    body: 'The campaign was successfully deleted',
                    isHidden: false
                })
            }
        }
    }

}
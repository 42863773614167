
import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { AdminSegmentDto, BasePubmaticSegment, Segment, SegmentDto, SegmentReach } from '../types/Segment'
import { CampaignDto } from '../types/Campaign'
import { CompanyData, Company } from '../types/Company'
import { CompanyReport } from '../types/CompanyReport'
import { BaseDomain, Domain } from '../types/Domain'
import { User } from '../types/User'
import { UserInviteDto } from '../types/UserInviteDto'
import { AdSegment } from '../types/AdPlatforms'
import { Adtech } from '../types/Pricing'
export class AdminService extends ApiService {

    async getCompaniesAdmin(): Promise<CompanyData[]> {
        return this.get('/api/admin/v3/company')
    }

    async getReachAdmin(): Promise<SegmentReach[]> {
        return this.get(`/api/admin/v2/segment/reach`)
    }

    async createDomainAdmin(id: number, addresses: BaseDomain[]): Promise<Domain[]> {
        return this.post(`/api/admin/v2/company/${id}/domain`, addresses)
    }

    async updateCompanyDomainsAdmin(id: number, domains: Domain[]): Promise<Domain[]> {
        return this.patch(`/api/admin/v2/company/${id}/domains`, domains)
    }

    async getCompanyDomainsAdmin(id: number): Promise<Domain[]> {
        return this.get(`/api/admin/v2/company/${id}/domain`)
    }

    async getCompanySegmentsAdmin(id: number): Promise<Segment[]> {
        return this.get(`/api/admin/v3/company/${id}/segment`)
    }

    async deleteCompanyDomainAdmin(id: number, domainId: number): Promise<void> {
        return this.del(`/api/admin/v2/company/${id}/domain/${domainId}`)
    }

    async getCompanyReportsAdmin(id: number): Promise<CompanyReport[] | null> {
        return this.get(`/api/admin/v2/company/${id}/report`)
    }

    async getVereficationAdmin(userId: number, domain: string) {
        return this.post(`/api/admin/v2/verification`, { accountId: userId, domain: domain })
    }

    async getAccountAdmin(): Promise<User[] | null> {
        return this.get(`/api/admin/v2/account`)
    }

    async getUsersAdmin(companyId: number): Promise<User[]> {
        return this.get(`/api/admin/v2/company/${companyId}/account`)
    }

    async getAllSegmentsAdmin(): Promise<AdminSegmentDto[]> {
        return this.get(`/api/admin/v3/segment`)
    }

    async deleteSegment(id: number, companyId: number): Promise<void> {
        return this.del(`/api/admin/v3/segment/${id}?companyId=${companyId}`)
    }

    async deleteXandrSegment(id: number): Promise<void> {
        return this.del(`/api/admin/v2/xandr/${id}`)
    }

    async getXandrSegmentAdmin(id: number): Promise<SegmentDto[]> {
        return this.get(`/api/admin/v2/segment/xandr/${id}`)
    }

    async getSegmentAdmin(id: number): Promise<SegmentDto> {
        return this.get(`/api/admin/v3/segment/${id}`)
    }

    async estimateAdmin(segment: SegmentDto): Promise<{ size: number, singleSite: number }> {
        return this.post(`/api/admin/v2/segment/estimate`, segment)
    }

    async getCampaignsAdmin(): Promise<CampaignDto[]> {
        return this.get('/api/admin/v2/campaign')
    }

    async publishSegmentAdmin(id: number, companyId: number): Promise<SegmentDto> {
        return this.post(`/api/admin/v3/segment/${id}/publish?companyId=${companyId}`)
    }

    async archiveSegmentAdmin(id: number, companyId: number): Promise<SegmentDto> {
        return this.put(`/api/admin/v3/segment/${id}/archive?companyId=${companyId}`, null)
    }

    async updateXandrSegmentAdmin(segment: any): Promise<SegmentDto> {
        return this.put(`/api/admin/v2/segment/xandr/${segment.id}`, segment)
    }

    async updateSegmentAdmin(segment: SegmentDto): Promise<SegmentDto> {
        return this.put(`/api/admin/v2/segment/${segment.id}`, segment)
    }

    async updateDomainAdmin(companyId: number, domain: Domain): Promise<Domain> {
        return this.patch(`/api/admin/v2/company/${companyId}/domain`, domain)
    }

    async deleteSegmentAdmin(id: number, companyId: number): Promise<void> {
        return this.del(`/api/admin/v3/segment/${id}?companyId=${companyId}`)
    }

    async deleteXandrSegmentAdmin(id: number): Promise<void> {
        return this.del(`/api/admin/v2/xandr/${id}`)
    }

    async deleteCampaignAdmin(id: number): Promise<void> {
        return this.del(`/api/admin/v2/campaign/${id}`)
    }

    async createInviteAdmin(model: UserInviteDto): Promise<User> {
        return this.post(`/api/admin/v2/company/${model.companyId}/account`, model)
    }

    async getAdminSegmentExportStatus(companyId: number, segmentId: number, adtech: Adtech): Promise<AdSegment> {
        return this.get(`/api/admin/v3/company/${companyId}/segment/${segmentId}/export/${adtech}`)
    }

    async triggerDailyReport(): Promise<void> {
        return this.get(`https://customer-service.anonymised.io/api/admin/v3/internal/report`, null, true)
    }


}

export const adminService = new AdminService(baseApiUrl)

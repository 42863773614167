import { ApiService } from './apiService'
import { baseApiUrl } from '../config'
import { CampaignDto, CampaignProposal, CampaignStatus } from '../types/Campaign'

export class CampaignService extends ApiService {

    async create(campaign: CampaignDto): Promise<CampaignDto> {
        return this.post('/api/v2/campaign', campaign)
    }

    async createProposal(campaign: CampaignDto, status: string): Promise<CampaignProposal> {
        return this.post('/api/v2/campaign-proposal', { campaignId: campaign.id, status: status })
    }

    async edit(campaign: CampaignDto): Promise<CampaignDto> {
        return this.put(`/api/v2/campaign/${campaign.id}`, campaign)
    }

    async getProposals(): Promise<CampaignDto[]> {
        return this.get('/api/v2/campaign-proposal')
    }

    async getCampaigns(): Promise<CampaignDto[]> {
        return this.get('/api/v2/campaign')
    }

    async getMarketPublisher(): Promise<CampaignDto[]> {
        const url = '/api/v2/marketplace-campaigns'
        return this.get(url)
    }

    async changeStatus(campaignId: number, status: CampaignStatus): Promise<CampaignDto> {
        return this.put(`/api/v2/campaign/${campaignId}/${status}`)
    }

    async deleteCampaign(id: number): Promise<void> {
        return this.del(`/api/v2/campaign/${id}`)
    }

}

export const campaignService = new CampaignService(baseApiUrl)
import { ReactChild } from 'react'
import { SegmentDto } from './Segment'

export interface CampaignDto {
    proposals?: CampaignProposal[];
    id?: number;
    companyName?: string;
    companyEmail?: string;
    companyID?: number;
    name: string;
    clientName: string;
    dspName: string;
    seatId: string;
    timing: string;
    ads: { format: string, cpm: string | number }[];
    segments?: { id: number, publicName: string, name?: string }[];
    startDate: Date | string;
    endDate: Date | string;
    notes: string;
    frequencyCap: { [key: string]: number } | { freqRate: number, freqTiming: { label: string, value: number } }[] | [] | null;
    status: CampaignStatus;
}

export interface CampaignFormProps {
    campaign: CampaignDto;
    showRequiredSave: boolean;
    showRequiredPublish: boolean;
    excludeSegments: { value: SegmentDto | { id: number, publicName: string, name?: string }, label: string }[];
    segmentOptions: { value: SegmentDto, label: string }[];
    excludeAds: { value: { format: string, cpm: number }, label: string }[];
    handleSubmit: (e: any) => void;
    handlePublish: (e: any) => void;
    handleChange: (field: string, e: React.ChangeEvent<HTMLInputElement>, ad?: { format: string, cpm: number }) => void;
    handleSelect: (selected: { label: string, value: { cpm: number, format: string } }[]) => void;
    handleDeleteSegment: (id: number) => void;
    handleFreqTiming: (selected: { label: string, value: number }, i: number) => void;
    handleFreqRate: (e: any, i: number) => void;
    addFC: () => void;
    removeFC: (index: number) => void;
    handleFCScript: () => ReactChild | string;
}

export interface CampaignProposal {
    companyId: number;
    status: ProposalStatus;
    companyName: string;
}

export enum CampaignStatus {
    Draft = 'created',
    Published = 'published',
}

export enum ProposalStatus {
    Accepted = 'accepted',
    Rejected = 'rejected',
    Open = 'open'
}



import { ToastType } from '../types/ToastData'
import { adminService } from '../services/adminService'
import { makeAutoObservable, runInAction, onBecomeObserved } from 'mobx'
import { CompanyService } from '../services/companyService'
import { RootStore } from './rootStore'
import { CompanyData, Company } from '../types/Company'
import { CompanyReport } from '../types/CompanyReport'
import { BaseDomain, Domain } from '../types/Domain'
import { LocalStorageKeys } from '../types/LocalStorage'

export class CompanyStore {
    private companyService: CompanyService
    private rootStore: RootStore

    myCompany: CompanyData = null
    companyReports: CompanyReport[] = []
    companies: CompanyData[] = []
    companyDomains: Domain[]
    adminDomains: Domain[]
    isDemo: boolean = false

    companyDetails: any = {}
    taxTypeList: any = null
    error: string = null

    constructor(rootStore: RootStore, companyService: CompanyService) {
        this.companyService = companyService
        this.rootStore = rootStore
        this.companyDomains = []
        this.adminDomains = []

        makeAutoObservable(this)

        onBecomeObserved(this, 'companies', async () => {
            if (this.companies.length > 0) return
            const res = await adminService.getCompaniesAdmin() 
            runInAction(() => { this.companies = res })
        })

        onBecomeObserved(this, 'taxTypeList', async () => {
            if (this.taxTypeList) return
            const res = await this.companyService.getTaxTypeList()
            runInAction(() => { this.taxTypeList = res })
        })

        onBecomeObserved(this, 'companyDomains', async () => {
            if (this.myCompany) {
                if (this.companyDomains.length > 0) return
                const res = await this.companyService.getCompanyDomains(this.myCompany.company.id)
                runInAction(() => { this.companyDomains = res ? res : [] })
            }
        })

    }

    handleEmptyFields(company: Company, isSend: boolean) {

        const newData: Company = Object.entries(company).reduce((newData: any, [key, val]) => {
            if (isSend) {
                val === '' ? newData[key] = 'n/a' : newData[key] = val
            }
            else {
                val === 'n/a' ? newData[key] = '' : newData[key] = val
            }
            return newData
        }, {})

        return newData
    }

    setCompanyDetails(companyDetails: any) {
        this.companyDetails = companyDetails
    }

    showSelectedCompany(company: Company) {
        const index = this.companies.findIndex((x) => {
            return x.company.id === company.id
        })
        this.companies.unshift(this.companies[index])
        this.companies.splice(index + 1, 1)
    }

    filterAdminDomains = (companyIds: number[]) => {
        this.adminDomains = this.adminDomains.filter(x => companyIds.includes(x.companyId))
    }

    sortDomains = (domains: Domain[]) => {
        return domains.sort((a, b) => a.address.localeCompare(b.address)).map((x) => x.comment === 'n/a' ? { ...x, comment: '' } : x)
    }

    async create(company: any) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.companyService.create(company)
            runInAction(() => { this.companies = [...this.companies, {company: res, integrations: null}] })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to create the company'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getUserCompany(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.companyService.getUserCompany(id)
            runInAction(() => {
                this.myCompany = res
                // harcoded demo company check
                if (res?.company?.id === 2234) {
                    this.isDemo = localStorage.getItem(LocalStorageKeys.allowDemoFeatures) !== 'true'
                }
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get the company'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyReportsAdmin(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await adminService.getCompanyReportsAdmin(id)
            runInAction(() => {
                this.companyReports = res
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company reports'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyDomains(id: number) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await this.companyService.getCompanyDomains(id)
            runInAction(() => {
                this.companyDomains = this.sortDomains(res)
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company domains'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyDomainsAdmin(id: number, multiCompanies: boolean) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await adminService.getCompanyDomainsAdmin(id)
            runInAction(() => {
                this.adminDomains = multiCompanies ? [...this.adminDomains, ...this.sortDomains(res)] : this.sortDomains(res)
            })
        } catch (error) {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to get company domains'
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async createCompanyDomainsAdmin(id: number, address: BaseDomain | BaseDomain[]) {
        const newDomains = []
        try {
            this.rootStore.appStore.incrementRequests()
            const domains = Array.isArray(address) ? await adminService.createDomainAdmin(id, address) : await adminService.createDomainAdmin(id, [address])
            runInAction(() => {
                this.adminDomains = [...this.adminDomains, ...domains]
                newDomains.push(...domains)
            })
        } catch (error) {
            runInAction(() => { this.error = error.message ? error.message : error })
            if (error.message) {
                throw error.message.includes('record already exists') ? 'The domain already exists' : 'Error occurred while adding a domain'
            }
            else {
                throw error.includes('record already exists') ? 'The domain already exists' : 'Error occurred while adding a domain'
            }
        } finally {
            this.rootStore.appStore.decrementRequests()

            const domainsLength = Array.isArray(address) ? address.length : 1

            if (newDomains.length > 0 && newDomains.length === domainsLength) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated',
                    body: `${newDomains.length > 1 ? `${newDomains.length} domains were` : `domain was`} added successfully`
                })
            }
            if (newDomains.length > 0 && newDomains.length !== domainsLength) {

                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated',
                    body: `${newDomains.length > 1 ? `\u2705 ${newDomains.length} domains were` : `\u2705 1 domain was`} added successfully.
                    \n${domainsLength - newDomains.length > 1 ? `\u274C ${domainsLength - newDomains.length} domains were` : `\u274C 1 domain was`} not added`
                })
            }

            if (newDomains.length === 0) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Updated',
                    body: `${(Array.isArray(address) && address.length) > 1 ? `${address.length} domains were` : `domain was`} not added`
                })
            }
        }
    }

    async createCompanyDomains(id: number, address: BaseDomain | BaseDomain[]) {
        const newDomains = []
        try {
            this.rootStore.appStore.incrementRequests()
            const domains = Array.isArray(address) ? await this.companyService.createDomains(id, address) : await this.companyService.createDomains(id, [address])
            runInAction(() => {
                this.companyDomains = [...this.companyDomains, ...domains]
                newDomains.push(...domains)
            })
        } catch (error) {
            runInAction(() => { this.error = error.message ? error.message : error })
            if (error.message) {
                throw error.message.includes('record already exists') ? 'The domain already exists' : 'Error occurred while adding a domain'
            }
            else {
                throw error.includes('record already exists') ? 'The domain already exists' : 'Error occurred while adding a domain'
            }
        } finally {
            this.rootStore.appStore.decrementRequests()

            const domainsLength = Array.isArray(address) ? address.length : 1

            if (newDomains.length > 0 && newDomains.length === domainsLength) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated',
                    body: `${newDomains.length > 1 ? `${newDomains.length} domains were` : `domain was`} added successfully`
                })
            }
            if (newDomains.length > 0 && newDomains.length !== domainsLength) {

                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated',
                    body: `${newDomains.length > 1 ? `\u2705 ${newDomains.length} domains were` : `\u2705 1 domain was`} added successfully.
                    \n${domainsLength - newDomains.length > 1 ? `\u274C ${domainsLength - newDomains.length} domains were` : `\u274C 1 domain was`} not added`
                })
            }

            if (newDomains.length === 0) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Error,
                    title: 'Not Updated',
                    body: `${(Array.isArray(address) && address.length) > 1 ? `${address.length} domains were` : `domain was`} not added`
                })
            }
        }
    }

    async updateCompanyDomain(domain: Domain) {
        try {
            this.rootStore.appStore.incrementRequests()
            const index = this.companyDomains.findIndex(x => x.id === domain.id)
            const res = await this.companyService.updateDomainStatus(domain.companyId, domain.id, domain.status)
            runInAction(() => {
                this.companyDomains[index] = res.comment === 'n/a' ? { ...res, comment: '' } : res
                this.companyDomains = [...this.companyDomains]
            })
        } catch (error) {
            runInAction(() => { this.error = error.message ? error.message : error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated!',
                body: 'The domain was not updated ',
                isHidden: false
            })
            throw error
        }
        finally {
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated!',
                    body: 'The domain was successfully updated ',
                    isHidden: false
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }

    async updateCompanyDomainAdmin(id: number, domain: Domain) {
        try {
            this.rootStore.appStore.incrementRequests()
            const index = this.adminDomains.findIndex(x => x.id === domain.id)

            const res = await adminService.updateDomainAdmin(id, domain.comment === '' ? { ...domain, comment: 'n/a' } : domain)
            runInAction(() => {
                this.adminDomains[index] = res.comment === 'n/a' ? { ...res, comment: '' } : res
            })
        } catch (error) {
            runInAction(() => { this.error = error.message ? error.message : error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated!',
                body: 'The domain was not updated ',
                isHidden: false
            })
            throw error
        } finally {
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated!',
                    body: 'The domain was successfully updated ',
                    isHidden: false
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }


    async updateCompanyDomainsAdmin(id: number, domains: Domain[]) {
        try {
            this.rootStore.appStore.incrementRequests()
            const res = await adminService.updateCompanyDomainsAdmin(id, domains.map(domain => domain.comment === '' ? { ...domain, comment: 'n/a' } : domain))
            runInAction(() => {
                this.adminDomains = this.adminDomains.map(existingDomain => {
                    const matchedUpdatedDomain = res.find(updatedDomain => updatedDomain.id === existingDomain.id)
                    return matchedUpdatedDomain ? {...matchedUpdatedDomain, comment: matchedUpdatedDomain.comment === 'n/a' ? '' : matchedUpdatedDomain.comment} : existingDomain
                })
            })
        } catch (error) {
            runInAction(() => { this.error = error.message ? error.message : error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated!',
                body: 'The domains were not updated ',
                isHidden: false
            })
            throw error
        } finally {
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated!',
                    body: 'The domains were successfully updated ',
                    isHidden: false
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }

    async deleteCompanyDomain(id: number, domainId: number) {
        try {
            this.rootStore.appStore.incrementRequests()

            await this.companyService.deleteCompanyDomain(id, domainId)
            runInAction(() => { this.companyDomains = this.companyDomains.filter(x => x.id !== domainId) })

        } catch (error) {
            //TODO: handle delete error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async deleteCompanyDomainAdmin(id: number, domainId: number) {
        try {
            this.rootStore.appStore.incrementRequests()

            await adminService.deleteCompanyDomainAdmin(id, domainId)
            runInAction(() => { this.adminDomains = this.adminDomains.filter(x => x.id !== domainId) })

        } catch (error) {
            //TODO: handle delete error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async update(id: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.companyService.update(this.companyDetails, id)
            const index = this.companies.findIndex(x => x.company.id === id)
            runInAction(() => {
                this.companies[index] = { company: this.handleEmptyFields(resp, false), integrations: this.myCompany.integrations }
                this.myCompany.company = resp
            })
        } catch (error) {
            this.companyDetails = {}
            runInAction(() => { this.error = error.message ? error.message : error})
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated!',
                body: 'The company was not updated ',
                isHidden: false
            })
            throw error
        } finally {
            this.companyDetails = {}
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated!',
                    body: 'The company was successfully updated ',
                    isHidden: false
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }

    async updateAdmin(id: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.companyService.updateAdmin(this.companyDetails, id)
            const index = this.companies.findIndex(x => x.company.id === id)

            runInAction(() => {
                this.companies[index] = { company: this.handleEmptyFields(resp, false), integrations: this.companies[index].integrations }
            })
        } catch (error) {
            this.companyDetails = {}
            runInAction(() => { this.error = error.message ? error.message : error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated!',
                body: 'The company was not updated ',
                isHidden: false
            })
            throw error
        } finally {
            this.companyDetails = {}
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated!',
                    body: 'The company was successfully updated ',
                    isHidden: false
                })
            }
            this.rootStore.appStore.decrementRequests()
        }
    }
}


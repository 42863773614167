import { makeAutoObservable, runInAction } from 'mobx'
import { AuthService } from '../services/authService'
import { RootStore } from './rootStore'
import { ToastType } from '../types/ToastData'
import { site } from '../config'
import { adminService } from '../services/adminService'
import { SiteType, UserStatus } from '../types'
import { Company } from '../types/Company'
import { User } from '../types/User'
import { UserInviteDto } from '../types/UserInviteDto'
import { UserResetDto } from '../types/UserResetDto'
import { SessionStorageKeys } from '../types/SessionStorage'
import { LocalStorageKeys } from '../types/LocalStorage'
import { UserRole } from '../types/UserRole'

export class AuthStore {
    private authService: AuthService
    private rootStore: RootStore

    idToken: string = null
    user: User = null
    //TODO: we should add users to every company or move this to companyStore
    users: User[] = []
    error: string | {
        cooldown: number, attemptsLeft: number, message: string
    } = null
    isCodeSent = false
    isUserLoginned: boolean = false

    constructor(rootStore: RootStore, authService: AuthService) {
        this.rootStore = rootStore
        this.authService = authService
        makeAutoObservable(this)
    }

    get isAdmin() {
        return this.user?.role === UserRole.Admin || this.user?.roleId === 100
    }

    gotCode(got: boolean) {
        this.isCodeSent = got
    }

    get userError() {
        if (this.error && typeof this.error === 'string' && this.error.includes(`Field validation for ${'Password'}`)) {
            return 'password is too short'
        }
        if (this.error === 'unauthorized') {
            return this.error
        }
        if (this.error === 'record already exists') {
            return this.error
        }
        if (this.error === 'you are not allowed to access this resource') {
            return 'you are not allowed'
        }
        if (this.error && typeof this.error !== 'string' && this.error.message === 'OTP not found') {
            return 'You entered a wrong verification code or your code has expired'
        }
        if (this.error && typeof this.error !== 'string' && this.error.message === 'invalid OTP' && this.error.attemptsLeft === 0) {
            return `wrong code, ${this.error?.attemptsLeft} attempts left, please resend your code and try again`
        }
        if (this.error && typeof this.error !== 'string' && this.error.attemptsLeft) {
            return `wrong code, ${this.error?.attemptsLeft} attempts left`
        }
        if (this.error && typeof this.error !== 'string' && this.error.cooldown) {
            return `code request timeout`
        }
        if (this.error && typeof this.error !== 'string' && this.error.message.includes('Email validation failed on the email tag')) {
            return 'Please enter a valid email address'
        }
        if (this.error && typeof this.error !== 'string' && this.error.message) {
            return this.error.message
        }
        if (this.error) {
            return this.error
        }

        return null
    }

    async resetPassword(model: UserResetDto) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.authService.resetPassword(model)

        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async invite(model: UserInviteDto) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const user = this.isAdmin ? await adminService.createInviteAdmin(model) : await this.authService.invite(model)
            runInAction(() => { this.users = [user, ...this.users] })
        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async delete(companyId: number, accountId: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.authService.delete(companyId, accountId)
            runInAction(() => {
                const index = this.users.findIndex(x => x.id === accountId)
                this.users[index].status = UserStatus.Deleted
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Deleted',
                body: error?.message || 'Something went wrong',
                isHidden: false
            })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Deleted',
                    body: 'User was successfully deleted',
                    isHidden: false
                })
            }
        }
    }

    async userVerification(userId: number, domain?: string) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()

            const i = this.users.findIndex(x => x.id == userId)

            if (i >= 0) {

                const code = this.isAdmin ? await adminService.getVereficationAdmin(this.users[i].id, domain) : await this.authService.getVerefication(this.users[i].email)
                const user = { ...this.users[i], code: code.code }
                const newUsers = [...this.users]
                newUsers.splice(i, 1, user)

                runInAction(() => { this.users = newUsers })
            }
        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async changeRole(user: User) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.authService.changeRole(user)

            runInAction(() => {
                const index = this.users.findIndex(x => x.id === user.id)
                this.users[index].role = user.role
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Not Updated',
                body: error?.message || 'Something went wrong',
                isHidden: false
            })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
            if (!this.error) {
                this.rootStore.appStore.setToastNotification({
                    type: ToastType.Success,
                    title: 'Updated',
                    body: 'User role was successfully changed',
                    isHidden: false
                })
            }
        }
    }

    async login(email: string, password: string) {
        runInAction(() => {
            this.error = null
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const idToken = await this.authService.login(email, password)
            runInAction(() => {
                if (idToken) {
                    localStorage.setItem('login-event', 'login-event' + Math.random())
                    this.idToken = idToken
                }
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            if (!this.error) {
                this.isCodeSent = true
            }
            this.rootStore.appStore.decrementRequests()
        }
    }

    async login2F(code: string, email: string) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const idToken = await this.authService.login2F(code, email)
            runInAction(() => {
                localStorage.setItem('login-event', 'login-event' + Math.random())
                this.idToken = idToken
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Success,
                title: 'Logined!',
                body: 'Login was succesfull',
                isHidden: true
            })
            this.rootStore.appStore.decrementRequests()
        }
    }

    async forgetPassword(email: string) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            await this.authService.forgetPassword(email)
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Success,
                title: 'Success',
                body: 'You will receive an email soon',
                isHidden: false
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    logout() {
        sessionStorage.removeItem(SessionStorageKeys.InsightsFilters)
        sessionStorage.removeItem(SessionStorageKeys.ExplorationDefault)
        sessionStorage.removeItem(SessionStorageKeys.SnapshotDefault)
        this.authService.logout()
        localStorage.setItem(LocalStorageKeys.LogoutEvent, 'logout-event' + Math.random())
        window.location.reload()
    }

    wrongCompany(company: Company) {
        localStorage.removeItem(LocalStorageKeys.AuthenticationCode)
        const redirect = (isAdvertiser: boolean) => {

            const currentHost = window.location.host
            const isLocalhost = currentHost.includes('localhost')

            const targetHost = isAdvertiser
                ? (isLocalhost ? 'localhost:9091' : currentHost.replace('publishers', 'advertisers'))
                : (isLocalhost ? 'localhost:9090' : currentHost.replace('advertisers', 'publishers'))

            alert(`You tried to log in to the ${isAdvertiser ? 'publisher' : 'advertiser'} website with ${isAdvertiser ? 'advertiser' : 'publisher'} credentials. You are being redirected to the ${isAdvertiser ? 'advertiser' : 'publisher'} website.`)

            const link = window.location.href.replace(currentHost, targetHost)
            window.location.href = link
        }

        redirect(company.isAdvertiser)
    }

    isCorrectCompany(company: Company, user: User) {

        const hasMismatch = (company: Company) => {
            return (company.isAdvertiser && site !== SiteType.Advertiser) || (company.isPublisher && site !== SiteType.Publisher)
        }

        if (!company.isAdvertiser && !company.isPublisher) {
            this.logout()
        }
        else if (hasMismatch(company)) {
            location.href = '/'
            this.wrongCompany(company)
        }
        else {
            this.user = user
            sessionStorage.setItem(SessionStorageKeys.Timeout, (60 * 60).toString())
        }
    }

    async getMe() {
        runInAction(() => {
            this.error = null
            this.isUserLoginned = false
        })
        try {
            this.rootStore.appStore.incrementRequests()
            const user = await this.authService.getMe()

            await this.rootStore.companyStore.getUserCompany(user.companyId)

            runInAction(() => {
                this.isCorrectCompany(this.rootStore.companyStore.myCompany?.company, user)
            })

        } catch (error) {
            if (error !== 'not found' && error.message !== 'unauthorized') {
                runInAction(() => { this.error = error })
                throw error
            }
        } finally {
            this.isUserLoginned = true
            this.rootStore.appStore.decrementRequests()
        }
    }
    //TODO: move to comppanyStore
    async getUsers(companyId: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const users = this.isAdmin ? await adminService.getUsersAdmin(companyId) : await this.authService.getUsers(companyId)
            runInAction(() => { this.users = users })
        } catch (error) {
            if (error !== 'not found' && error.message !== 'unauthorized') {
                runInAction(() => { this.error = error })
                throw error
            }
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }
}

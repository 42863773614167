import { ApiService } from './apiService'
import { baseApiUrl, site } from '../config'
import { SiteType } from '../types'
import { Adtech, BaseEquativPrice, BasePricesDto, BaseXandrPrice, CompanyIntegrationDto, CriteoDto, EquativDto, EquativPriceDto, GAMDto, PriceDto, PubmaticDto, XandrDto, XandrPriceDto } from '../types/Pricing'

export class PricingService extends ApiService {
    protected isPublisherWebSite: boolean

    constructor(baseUrl: string, siteType: SiteType) {
        super(baseUrl)
        this.isPublisherWebSite = siteType === SiteType.Publisher
    }

    async getXandrPrices(): Promise<XandrPriceDto[]> {
        return this.get('/api/admin/v2/xandr/price')
    }

    async getCompanyXandrPrice(id: number): Promise<XandrDto> {
        return this.get(`/api/admin/v2/xandr/company/${id}`)
    }

    async getSegmentXandrPrice(id: number): Promise<XandrPriceDto> {
        return this.get(` /api/admin/v2/xandr/price/segment/${id}`)
    }

    async getEquativPrices(): Promise<EquativPriceDto[]> {
        return this.get('/api/admin/v2/equativ/price')
    }

    async getCompanyEquativPrice(id: number): Promise<EquativDto> {
        return this.get(`/api/admin/v2/equativ/company/${id}`)
    }

    async getSegmentEquativPrice(id: number): Promise<EquativPriceDto> {
        return this.get(` /api/admin/v2/equativ/price/segment/${id}`)
    }

    async getCriteoPrices(): Promise<EquativPriceDto[]> {
        return this.get('/api/admin/v2/criteo/price')
    }

    async getCompanyCriteoPrice(id: number): Promise<CriteoDto> {
        return this.get(`/api/admin/v2/criteo/company/${id}`)
    }

    async getSegmentCriteoPrice(id: number): Promise<EquativPriceDto> {
        return this.get(` /api/admin/v2/criteo/price/segment/${id}`)
    }

    async getCompanyGAMPrice(id: number): Promise<GAMDto> {
        return this.get(`/api/admin/v2/gam/company/${id}`)
    }

    async getGAMPrices(): Promise<EquativPriceDto[]> {
        return this.get('/api/admin/v2/gam/price')
    }

    async getCompanyPubmaticPrice(id: number): Promise<PubmaticDto> {
        return this.get(`/api/admin/v2/pubmatic/company/${id}`)
    }

    async getPubmaticPrices(): Promise<EquativPriceDto[]> {
        return this.get('/api/admin/v2/pubmatic/price')
    }



    async createXandrPrice(prices: BaseXandrPrice): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/xandr/price`, prices)
    }
    async createEquativPrice(prices: BaseEquativPrice): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/equativ/price`, prices)
    }
    async createCriteoPrice(prices: BaseEquativPrice): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/criteo/price`, prices)
    }
    async createPubmaticPrice(prices: BaseEquativPrice): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/pubmatic/price`, prices)
    }
    async createCompanyXandrPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/xandr/company/${id}`, prices)
    }
    async createCompanyEquativPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/equativ/company/${id}`, prices)
    }
    async createCompanyCriteoPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/criteo/company/${id}`, prices)
    }
    async createCompanyGAMPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/gam/company/${id}`, prices)
    }
    async createCompanyPubmaticPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/pubmatic/company/${id}`, prices)
    }
    async createSegmentXandrPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/xandr/price/segment/${id}`, prices)
    }
    async createSegmentEquativPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/equativ/price/segment/${id}`, prices)
    }
    async createSegmentCriteoPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/criteo/price/segment/${id}`, prices)
    }
    async createSegmentPubmaticPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/pubmatic/price/segment/${id}`, prices)
    }



    async updateCompanyXandrPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.put(`/api/admin/v2/xandr/company/${id}`, prices)
    }
    async updateCompanyPubmaticPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/pubmatic/company/${id}`, prices)
    }
    async updateCompanyCriteoPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/criteo/company/${id}`, prices)
    }
    async updateCompanyGAMPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/gam/company/${id}`, prices)
    }
    async updateCompanyEquativPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/equativ/company/${id}`, prices)
    }
    async updateSegmentXandrPrice(id: number, prices: BasePricesDto): Promise<XandrPriceDto> {
        return this.put(`/api/admin/v2/xandr/price/segment/${id}`, prices)
    }
    async updateSegmentEquativPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/equativ/price/segment/${id}`, prices)
    }
    async updateSegmentCriteoPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/criteo/price/segment/${id}`, prices)
    }
    async updateSegmentPubmaticPrice(id: number, prices: BasePricesDto): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/pubmatic/price/segment/${id}`, prices)
    }



    async updateCompanyXandrSeatId(id: number, seatId: { [key: string]: number }): Promise<XandrPriceDto> {
        return this.put(`/api/admin/v2/xandr/company/${id}`, seatId)
    }
    async updateCompanyEquativSeatId(id: number, seatId: { seatId: string }): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/equativ/company/${id}`, seatId)
    }
    async updateCompanyCriteoSeatId(id: number, seatId: { seatId: number }): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/criteo/company/${id}`, seatId)
    }
    async updateCompanyGAMSeatId(id: number, seatId: { networkCode: string }): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/gam/company/${id}`, seatId)
    }
    async updateCompanyPubmaticSeatId(id: number, seatId: { publisherId: number }): Promise<EquativPriceDto> {
        return this.put(`/api/admin/v2/pubmatic/company/${id}`, seatId)
    }

    async createCompanyXandrSeatId(id: number, seatId: { [key: string]: number }): Promise<XandrPriceDto> {
        return this.post(`/api/admin/v2/xandr/company/${id}`, seatId)
    }
    async createCompanyEquativSeatId(id: number, seatId: { seatId: string }): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/equativ/company/${id}`, seatId)
    }
    async createCompanyCriteoSeatId(id: number, seatId: { seatId: number }): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/criteo/company/${id}`, seatId)
    }
    async createCompanyGAMSeatId(id: number, seatId: { networkCode: string }): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/gam/company/${id}`, seatId)
    }
    async createCompanyPubmaticSeatId(id: number, seatId: { publisherId: number }): Promise<EquativPriceDto> {
        return this.post(`/api/admin/v2/pubmatic/company/${id}`, seatId)
    }


    async getCompaniesXandr(seatId: number): Promise<string[]> {
        return this.get(`/api/admin/v2/xandr/seat/${seatId}`)
    }
    async getCompaniesEquativ(seatId: string): Promise<string[]> {
        return this.get(`/api/admin/v2/equativ/seat/${seatId}`)
    }
    async getCompaniesCriteo(seatId: number): Promise<string[]> {
        return this.get(`/api/admin/v2/criteo/seat/${seatId}`)
    }
    async getCompaniesGAM(seatId: string): Promise<string[]> {
        return this.get(`/api/admin/v2/gam/seat/${seatId}`)
    }
    async getCompaniesPubmatic(seatId: number): Promise<string[]> {
        return this.get(`/api/admin/v2/pubmatic/seat/${seatId}`)
    }

    async getCompanyIntegration(adtech: Adtech, id: number): Promise<CompanyIntegrationDto | null> {
        return this.get(`/api/admin/v3/${adtech}/company/${id}`)
    }

    async getAdtechPrices(): Promise<PriceDto[]> {
        return this.get(`/api/admin/v3/price`)
    }

    async getCompaniesByIntegration(adtech: Adtech, id: string): Promise<string[]> {
        return this.get(`/api/admin/v3/${adtech}/seat/${id}`)
    }

    async updateCompanyIntegration(adtech: Adtech, id: number, data: CompanyIntegrationDto): Promise<CompanyIntegrationDto> {
        return this.put(`/api/admin/v3/${adtech}/company/${id}`, data)
    }

    async createCompanyIntegration(adtech: Adtech, id: number, data: CompanyIntegrationDto): Promise<CompanyIntegrationDto> {
        return this.post(`/api/admin/v3/${adtech}/company/${id}`, data)
    }

}

export const pricingService = new PricingService(baseApiUrl, site as SiteType)

import { makeAutoObservable, runInAction, onBecomeObserved } from 'mobx'
import { SegmentDto } from '../types'
import { SegmentService } from '../services/segmentService'
import { RootStore } from './rootStore'
import { ToastType } from '../types/ToastData'

export class MarketplaceStore {
    private segmentService: SegmentService
    private rootStore: RootStore

    segments: SegmentDto[] = []
    error: string = null

    constructor(rootStore: RootStore, segmentService: SegmentService) {
        this.segmentService = segmentService
        this.rootStore = rootStore


        makeAutoObservable(this)
        onBecomeObserved(this, 'segments', async () => {
            if (this.segments.length > 0) return
            const res = this.rootStore.companyStore.myCompany.isAdvertiser ? await this.segmentService.getMarketAdvertiser() : []

            runInAction(() => { this.segments = res ? res : [] })
        })
    }

    async estimate(id: number) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.segmentService.estimateMarketplaced(id)
            runInAction(() => {
                const index = this.segments.findIndex(x => x.id === id)
                this.segments[index].size = resp.size
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to estimate the size'
            })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async filter(query: string) {
        runInAction(() => { this.error = null })
        try {
            this.rootStore.appStore.incrementRequests()
            const resp = await this.segmentService.filterMarket(query)
            runInAction(() => {
                this.segments = resp
            })
        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed to filter the segments'
            })
            throw error
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

}
import { LocalStorageKeys } from '../types/LocalStorage'
import { SessionStorageKeys } from '../types/SessionStorage'

export abstract class ApiService {
    protected baseUrl: string

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
    }

    protected authHeader() {
        const auth = JSON.parse(localStorage.getItem(LocalStorageKeys.AuthenticationCode))

        if (auth && auth.token) {
            return { 'Authorization': 'Bearer ' + auth.token }
        }
        throw new Error('unauthorized')
    }

    protected tryParseTextToJson(text: string) {
        try {
            return text && JSON.parse(text)
        } catch (error) {
            return { message: text }
        }
    }

    protected async handleResponse(response: any) {
        const text = await response.text()
        const data = this.tryParseTextToJson(text)

        if (!response.ok) {
            if (response.status === 401 && window.location.pathname !== '/login') {
                window.location.href = '/login'
                localStorage.removeItem(LocalStorageKeys.AuthenticationCode)
                localStorage.setItem(LocalStorageKeys.LogoutEvent, 'logout-event' + Math.random())
                sessionStorage.setItem(SessionStorageKeys.Message, 'Unauthorized access. Please login again.')
                return Promise.reject({ message: 'Unauthorized access. Please login again.' })
            }
            const error = (data && { message: data.message, attemptsLeft: data.attemptsLeft, cooldown: data.cooldown }) || response.statusText
            return Promise.reject(error)
        }
        sessionStorage.removeItem(SessionStorageKeys.Message)
        return data
    }

    protected async get(url: string, headers?: any, differentBaseUrl?: boolean) {
        const header = this.authHeader()

        const requestOptions = {
            method: 'GET',
            headers: { ...header, ...headers }
        }
        const response = differentBaseUrl ? await fetch(`${url}`, requestOptions) : await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async post(url: string, dto?: any, differentBaseUrl?: boolean, isIncognito = false) {
        const header = isIncognito ? {} : this.authHeader()

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = differentBaseUrl ? await fetch(`${url}`, requestOptions) : await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async put(url: string, dto?: any) {
        const header = this.authHeader()
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async patch(url: string, dto: any) {
        const header = this.authHeader()

        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json', ...header },
            body: JSON.stringify(dto)
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }

    protected async del(url: string): Promise<void> {
        const header = this.authHeader()

        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', ...header }
        }
        const response = await fetch(`${this.baseUrl}${url}`, requestOptions)

        return this.handleResponse(response)
    }
}
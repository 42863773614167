export interface XandrDto {
  companyId: number
  createdAt: string
  curateSeatId: number
  monetizeSeatId: number
  seatId: string
  retargetingPriceId: number
  targetingPriceId: number
}

export interface EquativDto {
  companyId: number
  createdAt: string
  retargetingPriceId: number
  seatId: string
  targetingPriceId: number
}

export interface CriteoDto {
  companyId: number
  createdAt: string
  retargetingPriceId: number
  seatId: string
  targetingPriceId: number
}

export interface GAMDto {
  companyId: number
  createdAt: string
  retargetingPriceId: number
  networkCode: string
  targetingPriceId: number
  seatId?: string
}

export interface PubmaticDto {
  companyId: number
  createdAt: string
  retargetingPriceId: number
  publisherId: number
  targetingPriceId: number
  seatId?: number
}

export interface XandrPriceDto {
  id: number
  name: string
  externalId: number
  defaultTargeting: boolean
  defaultRetargeting: boolean
  monetizeSeatId: string
  curateSeatId: string
  price: number
  currency: PriceCurrency
  createdAt: string
}

export interface EquativPriceDto {
  id: number
  name: string
  price: number
  currency: PriceCurrency
  defaultTargeting: boolean
  defaultRetargeting: boolean
  seatId: string
  createdAt: string
}

export interface XandrPrice extends XandrPriceDto {
  targeting: BasePricesDto
  retargeting: BasePricesDto
}

export interface EquativPrice extends EquativPriceDto {
  targeting: BasePricesDto
  retargeting: BasePricesDto
}

export interface BasePricesDto {
  id: number
  currency: PriceCurrency
  targetingPriceID: number
  retargetingPriceID: number
}

export interface BaseEquativPrice {
  name: string
  externalId: number
  defaultTargeting: boolean
  defaultRetargeting: boolean
}

export interface BaseXandrPrice {
  name: string
  price: number
  defaultTargeting: boolean
  defaultRetargeting: boolean
}

export interface ReturnedPriceDto {
  targeting: {
    id: number
    name: string
  } | null
  retargeting: {
    id: number
    name: string
  } | null
}

export interface CompanyPriceData {
  targeting: XandrPriceDto | EquativPriceDto
  retargeting: XandrPriceDto | EquativPriceDto
}

export interface PriceDto {
  id: number
  name: string
  price: number
  external_price: number
  defaultTargeting: boolean
  defaultRetargeting: boolean
  currency: PriceCurrency
  ad_tech_config: {
    xandr_curate: {
      external_id: number
    }
    xandr_monetize: {
      external_id: number
    }
  }
}

export interface SegmentPrice {
  ad_tech_name: Adtech;
  price: number;
  currency: PriceCurrency;
}

export interface CompanyIntegrationDto {
  companyID: number
  adTechName: Adtech
  targetingPriceID: number
  retargetingPriceID: number
  seatID: string
}

export interface CompanyIntegration {
  adTechName: Adtech
  seatID: string
}

export enum PriceCurrency {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
}

export enum PriceSender {
  Xandr = 'xandr',
  Pubmatic = 'pubmatic',
  GAM = 'gam',
  Equativ = 'equativ',
  Criteo = 'criteo'
}

export enum Adtech {
  XandrCurate = 'xandr_curate',
  XandrMonetize = 'xandr_monetize',
  Criteo = 'criteo',
  Equativ = 'equativ',
  GAM = 'gam',
  Pubmatic = 'pubmatic',
  Onetag = 'onetag'
}

export enum PriceReceiver {
  Company = 'company',
  Segment = 'segment',
}

export enum PriceAction {
  Delete = 'delete',
  Update = 'update',
  Add = 'add',
}

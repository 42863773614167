export enum LocalStorageKeys {
    ExpirationTime = 'exp',
    AuthenticationCode = 'auth',
    NewSegmentUI = 'newSegmentUI',
    SnapshotTour = 'snapshotTour',
    ExplorationTour = 'explorationTour',
    TargetingTour = 'targetingTour',
    RetargetingTour = 'retargetingTour',
    showTopics = 'showTopics',
    showDemographics = 'showDemographics',
    allowSegmentCopy = 'allowSegmentCopy',
    allowPubmatic = 'allowPubmatic',
    maintenanceMode = 'maintenance',
    LogoutEvent = 'logout-event',
    LoginEvent = 'login-event',
    useClickHouse = 'useClickHouse',
    allowDemoFeatures = "allowDemoFeatures"
}
import { makeAutoObservable, runInAction, onBecomeObserved } from 'mobx'
import { RootStore } from './rootStore'
import { PricingService } from '../services/pricingService'
import { Adtech, BasePricesDto, CompanyIntegrationDto, CriteoDto, EquativDto, EquativPrice, EquativPriceDto, GAMDto, PriceCurrency, PriceDto, PriceSender, PubmaticDto, XandrDto, XandrPrice, XandrPriceDto } from '../types/Pricing'
import { ToastType } from '../types/ToastData'
import { Company } from '../types/Company'

export class PricingStore {
    private pricingService: PricingService
    private rootStore: RootStore

    error: { message: string } = null
    integrations: CompanyIntegrationDto[] = []
    prices: PriceDto[] = []


    constructor(rootStore: RootStore, pricingService: PricingService) {
        this.pricingService = pricingService
        this.rootStore = rootStore

        makeAutoObservable(this)

        onBecomeObserved(this, 'prices', async () => {
            const res = await this.pricingService.getAdtechPrices()
            this.rootStore.appStore.pendingRequests++
            runInAction(() => {
                this.prices = res ? res : []
            })
            this.rootStore.appStore.pendingRequests--
        })
    }

    trimPriceName(name: string) {
        return name.split(' ')[name.split(' ').length - 1]
    }

    async getCompaniesBySeatId(seatId: string, type: Adtech) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()
            let companies: string[] = []

            companies = await this.pricingService.getCompaniesByIntegration(type, seatId)

            return companies || [] as string[]

        } catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to get companies by ${type} seat id`
            })
        } finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async getCompanyIntegration(company: Company, adtech: Adtech) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            const res = await this.pricingService.getCompanyIntegration(adtech, company.id)

            const handleIntegration = () => {

                const companyExists = this.integrations.some(integration => integration.companyID === res.companyID);

                if (companyExists) {
                    return this.integrations.map(integration =>
                        integration.companyID === res.companyID ? res : integration
                    )
                } else {
                    return [res]
                }
            }

            runInAction(() => {
                this.integrations = res
                    ? handleIntegration()
                    : this.integrations
            })
        }
        catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to get company ${adtech} integration`
            })
        }
        finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async updateCompanyIntegration(company: Company, integration: CompanyIntegrationDto) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            const res = await this.pricingService.updateCompanyIntegration(integration.adTechName, company.id, integration)

            runInAction(() => {
                const index = this.integrations.findIndex(x => x.adTechName === integration.adTechName)
                this.integrations[index] = res
            })
        }
        catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to update company ${integration.adTechName} integration`
            })
        }
        finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

    async createCompanyIntegration(company: Company, integration: CompanyIntegrationDto) {
        try {
            this.error = null
            this.rootStore.appStore.incrementRequests()

            const res = await this.pricingService.createCompanyIntegration(integration.adTechName, company.id, integration)

            runInAction(() => {
                this.integrations = [...this.integrations, res]
            })
        }
        catch (error) {
            runInAction(() => { this.error = error })
            this.rootStore.appStore.setToastNotification({
                type: ToastType.Error,
                title: 'Failed',
                body: `Failed to create company ${integration.adTechName} integration`
            })
        }
        finally {
            this.rootStore.appStore.decrementRequests()
        }
    }

}